.main-wrapper {
  height: 100%;
  margin-top: rem(72);
  transition: margin 0.3s ease-in-out;
  @include breakpoint(mobile) {
    margin-top: 48px;
  }
  &.navShow {
    margin-top: 0;
  }
}

.policy {
  @include same(100%);
  overflow-y: hidden;
  iframe {
    @include same(100%);
  }
}