header {
  @include flex(flex-start, center, row);
  background: rgba(30, 30, 30, 0.90);
  backdrop-filter: blur(10px);
  position: fixed;
  top: rem(-72);
  left: 0;
  z-index: 900;
  width: 100%;
  height: rem(72);
  transition: top 0.3s ease-in-out;
  @include breakpoint(mobile) {
    top: -48px;
    height: 48px;
  }
  &.show {
    top: 0;
  }
  .navigation {
    @include flex(space-between, center, row);
    padding: 0 rem(57) 0 rem(36);
    width: 100%;
    @include breakpoint(mobile) {
      padding: 0 16px;
    }
    &__inner {
      @include flex(flex-start, center, row);
      gap: rem(92);
      @include breakpoint(mobile) {
        gap: 24px;
      }
      .gnb {
        @include flex(flex-start, center, row);
        gap: 64px;
        li {
          @include string(rem(16), rem(24), normal, 500, left, var(--white), 'normal');
          cursor: pointer;
        }
      }
      .m-gnb {
        @include string(14px, 20px, normal, 500, left, var(--white), 'normal');
      }
      .logo {
        @include size(rem(80), rem(52));
        cursor: pointer;
        @include breakpoint(mobile) {
          @include size(54px, 36px);
        }
      }
    }
    .sns-wrap {
      @include flex(flex-start, center, row);
      gap: rem(24);
      @include breakpoint(mobile) {
        gap: 16px;
      }
      img {
        @include same(rem(32));
        cursor: pointer;
        @include breakpoint(mobile) {
          @include same(24px);
        }
      }
    }
    .m-meun-btn {
      @include same(24px);
      background: url($G-path-icon + 'menu-open.svg') $G-img-contain;
    }
  }
}


.m-nav-wrapper {
  @include same(100%);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  background: rgba(30, 30, 30, 0.90);
  backdrop-filter: blur(10px);
  transform: translateX(100%);
  transition: all 0.2s ease-out;
  &.open {
    transform: translateX(0);
  }
  .m-nav-inner {
    @include flex(flex-start);
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
    .m-nav-head {
      position: absolute;
      top: 0;
      left: 0;
      @include flex(flex-end, center, row);
      width: calc(100% - 32px);
      height: 48px;
      padding: 0 16px;
      .m-menu-close {
        @include same(24px);
        background: url($G-path-icon + 'menu-close.svg') $G-img-contain;
      }
    }
    .m-nav-body {
      @include flex(space-between, center);
      height: 100%;
      padding: 80px 0 40px;
      width: calc(100% - 48px);
      .gnb {
        @include flex(flex-start, center);
        li {
          @include string(16px, 24px, normal, 500, left, var(--white), 'normal');
          cursor: pointer;
          &:not(:last-of-type) {
            margin-bottom: 40px;
          }
        }
      }
      .foot-container {
        width: 100%;
        .sns-wrap {
          @include flex(center, center, row);
          gap: 24px;
          margin-top: 40px;
          padding-top: 40px;
          border-top: 1px solid #585858;
          img {
            @include same(32px);
            cursor: pointer;
          }
        }
      }
    }
  }
}