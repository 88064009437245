// pretendard
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

// Black Han Sans
@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');

@font-face {
  font-family: 'Pretendard';
  src: url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');
}

@font-face {
  font-family: 'Black Han Sans', sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');
}