.main {
  .content-info {
    &__desc {
      &--title {
        @include string(rem(48), rem(60), normal, 400, left, var(--black), 'option');
        @include breakpoint(tablet) {
          @include string(28px, normal, normal, 400, center, var(--black), 'option');
        }
      }
      &--sub {
        @include string(rem(16), rem(24), normal, 600, left, var(--white), 'normal');
        @include breakpoint(tablet) {
          @include string(14px, 20px, normal, 400, center, var(--white), 'normal');
        }
      }
      &--primary {
        @include string(rem(16), rem(24), normal, 600, left, #FFD600, 'normal');
        @include breakpoint(tablet) {
          @include string(14px, 20px, normal, 400, center, #FFD600, 'normal');
        }
      }
    }
    &__btnwrap {
      @include flex(flex-start, center, row);
      gap: rem(16);
      margin-top: rem(83);
    }
  }
  .main-banner {
    padding-bottom: 43.125%;
      background: url($G-path-content + 'main-banner.png') $G-img-cover;
      @include breakpoint(mobile) {
        padding-bottom: 110%;
        background: url($G-path-content + 'm-main-banner.png') no-repeat bottom center / cover;
      }
  }
  .section01 {
    @include flex(flex-start);
    position: relative;
    &::after, &::before {
      content: '';
      display: block;
    }
    &::after {
      @include same(100%);
      max-height: rem(468);
      max-width: rem(222);
      aspect-ratio: 37 / 78;
      position: absolute;
      bottom: 0;
      left: 0;
      background: url($G-path-content + 'section01_bg.png') $G-img-contain;

      @include breakpoint(tablet) {
        // max-height: rem(468, 21);
        // max-width: rem(222, 21);
      }
      @include breakpoint(mobile) {
        max-width: 83.01px;
        max-height: 175px;
      }
    }
    &::before {
      @include same(100%);
      max-height: rem(512);
      max-width: rem(368);
      aspect-ratio: 23 / 32;
      position: absolute;
      bottom: 0;
      right: 0;
      background: url($G-path-content + 'section01-02_bg.png') $G-img-contain;
      @include breakpoint(tablet) {
        // max-height: rem(512, 21);
        // max-width: rem(368, 21);
      }
      @include breakpoint(mobile) {
        max-width: 131.53px;
        max-height: 183px;
      }
    }
    &__inner {
      @include flex(space-between, flex-end, row);
      gap: rem(91);
      height: 100%;
      max-width: calc(100% - 32px);
      width: var(--G-max-pc-width);
      @include breakpoint(ready) {
        width: var(--G-max-tablet-width);
      }
      @include breakpoint(tablet) {
        flex-direction: column-reverse;
        align-items: center;
        gap: 0;
        padding: 80px 0 0;
      }
      @include breakpoint(mobile) {
      }
      .phone-wrapper {
        @include flex(flex-end, center);
        height: 100%;
        position: relative;
        z-index: 1;
        padding-left: rem(130);
        @include breakpoint(tablet) {
          padding: 82px 0 0;
          transform: translateX(20%);
        }
        @include breakpoint(mobile) {
          @include flex(center, flex-end, row);
          padding: 21px 0 0;
          transform: translateX(-6.5%);
          gap: 8px;
        }
        .phone {
          // max-width:100%;
          // height:auto;
          // width: rem(334);
          opacity: 0;
          @include breakpoint(tablet) {
            width: rem(334, 21);
          }
          @include breakpoint(mobile) {
            width: 160px;
            position: relative;
            z-index: 1;
          }
        }
        .npc {
          // max-width:100%;
          // height:auto;
          max-width: rem(338);
          max-height: rem(658);
          aspect-ratio: 169 / 329;
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: -2;
          transform: translateX(-87.5%);
          // background: url($G-path-content + 'npc_img.png') $G-img-contain;
          @include breakpoint(tablet) {
            max-width: rem(338, 21);
            max-height: rem(658, 21);
          }
          @include breakpoint(mobile) {
            max-width: 165px;
            height:auto;
            max-height: auto;
            position: relative;
            transform: translateX(20%);
          }
        }
      }
      .content-info {
        padding: rem(166) 0;
        position: relative;
        z-index: 3;
        @include breakpoint(tablet) {
          padding: 0;
        }
        &__desc {
          @include breakpoint(tablet) {
            @include flex;
          }
          &--title {
            @include string(rem(48), rem(60), normal, 400, left, var(--black), 'option');
            @include breakpoint(tablet) {
              @include string(28px, normal, normal, 400, center, var(--black), 'option');
            }
          }
          ul {
            margin-top: 16px;
            li {
              @include string(rem(16), rem(24), normal, 400, left, var(--text), 'normal');
              @include breakpoint(tablet) {
                @include string(14px, 20px, normal, 400, center, var(--text), 'normal');
              }
              &:not(:last-of-type) {
                margin-bottom: rem(28);
              }
            }
          }
        }
        &__btnwrap {
          @include flex(flex-start, center, row);
          gap: rem(16);
          margin-top: rem(83);
          @include breakpoint(tablet) {
            @include flex;
            margin-top: 61px;
            gap: 0;
            a {
              &:not(:last-of-type) {
                margin-bottom: 24px;
              }
            }
            button {
              margin-bottom: 24px;
            }
          }
        }
      }
    }
  }

  .section02 {
    @include flex;
    background: #4F4CE2;
    padding: rem(86) 0 rem(62);
    @include breakpoint(tablet) {
      padding: 80px 0 88px;
    }
    &__inner {
      @include flex(space-between, center, row);
      gap: rem(57);
      max-width: calc(100% - 32px);
      width: var(--G-max-pc-width);
      @include breakpoint(ready) {
        width: var(--G-max-tablet-width);
      }
      @include breakpoint(tablet) {
        @include flex;
      }
      .content-info {
        &__desc {
          @include breakpoint(tablet) {
            @include flex;
          }
          &--title {
            color: var(--white);
            text-transform: uppercase;
            @include breakpoint(tablet) {
              text-align: center;
              margin-bottom: 16px;
            }
          }
          &--sub {
            .break {
              display: none;
            }
            @include breakpoint(tablet) {
              text-align: center;
              .break {
                display: block;
              }
            }
            @include breakpoint(mobile) {
              .break {
                display: none;
              }
            }
          }
        }
      }
      img {
        max-height: rem(350);
        // aspect-ratio: 267 / 175;
        max-width:100%;
        height:auto;
        @include breakpoint(tablet) {
          margin-top: 40px;
        }
      }
    }
  }
  .section03 {
    @include flex;
    background: linear-gradient(180deg, #33339C 0%, #5959D3 100%);
    position: relative;
    &::after {
      @include same(100%);
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: url($G-path-content + 'section03-bg.png') $G-img-cover;
    }
    &__inner {
      @include flex;
      position: relative;
      z-index: 1;
      max-width: calc(100% - 32px);
      width: var(--G-max-pc-width);
      padding: rem(160) 0 rem(100);
      @include breakpoint(ready) {
        max-width: calc(100% - 32px);
        width: var(--G-max-tablet-width);
      }
      @include breakpoint(tablet) {
        padding: 80px 0;
      }
      .content-info {
        &__desc {
          @include flex;
          &--title {
            color: var(--white);
            margin-bottom: 16px;
            @include breakpoint(tablet) {
              text-align: center;
            }
          }
          &--sub {
            @include breakpoint(tablet) {
              text-align: center;
            }
          }
          &--primary{
            margin-top: rem(8);
            @include breakpoint(tablet) {
              text-align: center;
              margin-top: 16px;
            }
          }
        }
      }
      .ingame-wrapper {
        margin-top: rem(72);
        display: grid;
        grid-template-columns: repeat(3, auto);
        gap: rem(26);
        @include breakpoint(tablet) {
          grid-template-columns: 1fr;
          grid-auto-rows: auto;
          gap: 40px;
        }
        // gap: 26px;
        // @include flex(space-between, center, row);
        // align-items: center;
        // padding: 0 rem(50);
        .ingame-info {
          @include flex;
          @include breakpoint(tablet) {
            @include flex(center, center, row);
            position: relative;
          }
          p {
            @include string(20px, normal, normal, 400, center, #FFD600, 'option');
            @include breakpoint(tablet) {
              position: absolute;
              top: 50%;
              left: calc(50% + 25px);
              transform: translateY(-50%);
            }

          }
          img {
            margin-bottom: rem(10);
            @include breakpoint(tablet) {
              @include size(61.79px, 24.39px);
              transform: rotate(-270deg);
              margin-bottom: 0;
            }
          }
        }
        .ingame-box {
            background-image: linear-gradient(to bottom, #ffda1d, #f7bb02);
            padding: 10px;
            border-radius: 10px;
            box-shadow: 0 10px 30px rgba(0,0,0,0.1), 0 6px 30px rgba(0,0,0,0.15);
            @include breakpoint(tablet) {
              padding: 6px;
            }
          .ingame-border {
            border: 4px solid #d77706;
            border-radius: 3px;
            @include breakpoint(tablet) {
              border-width: 3px;
            }
            .ingame-box-inner {
              background-color: #533be5;
              box-shadow: 0 0 0 15px #735cff inset;
              padding: rem(50) rem(40);
              @include breakpoint(tablet) {
                box-shadow: 0 0 0 10px #735cff inset;
                padding: 35px 25px;
              }
              &.ingame {
                ul li {
                  .symbol-wrapper {
                    position: relative;
                    &::before {
                      @include size(rem(39), rem(37));
                      content: '';
                      display: block;
                      background: url($G-path-symbol + 'Solicoin.png') $G-img-contain;
                      position: absolute;
                      bottom: rem(2);
                      right: rem(-11);
                      @include breakpoint(tablet) {
                        @include size(24px, 21px);
                        right: -6.5px;
                        bottom: 1px;
                      }
                    }
                  }
                  @include breakpoint(tablet) {
                    span {
                      display: block;
                    }
                  }
                }
              }
              ul {
                display: grid;
                grid-template-columns: repeat(4, rem(84));
                align-items: center;
                gap: rem(51) rem(20);
                @include breakpoint(tablet) {
                  grid-template-columns: repeat(4, 48px);
                  gap: 28px 12px;
                }
                li {
                  @include flex;
                  .symbol-wrapper {
                    margin-bottom: 16px;
                    @include breakpoint(tablet) {
                      margin-bottom: 8px;
                    }
                    img {
                      @include same(rem(84));
                      @include breakpoint(tablet) {
                        @include same(48px);
                      }
                    }
                  }
                  p, span {
                    @include string(rem(16), rem(24), normal, 400, center, var(--white), 'option');
                    text-wrap: nowrap;

                    @include breakpoint(tablet) {
                      @include string(12px, normal, normal, 400, center, var(--white), 'option');
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .section04 {
    @include flex;
    background: var(--white);
    padding: rem(65) 0 rem(69);
    @include breakpoint(tablet) {
      padding: 80px 0;
    }
    &__inner {
      @include flex(space-between, center, row);
      max-width: calc(100% - 32px);
      width: var(--G-max-pc-width);
      @include breakpoint(ready) {
        width: var(--G-max-tablet-width);
      }
      @include breakpoint(tablet) {
        @include flex;
      }
      .content-info {
        &__desc {
          @include breakpoint(tablet) {
            @include flex;
          }
          &--title {
            @include breakpoint(tablet) {
              text-align: center;
              margin-bottom: 16px;
            }
          }
          &--sub {
            color: var(--text);
            @include breakpoint(tablet) {
              text-align: center;
              .break {
                display: none;
              }
            }
          }
        }
      }
      img {
        max-width: 100%;
        height: auto;
        @include breakpoint(tablet) {
          margin-top: 32px;
        }
      }
    }
  }

  .section05 {
    @include flex;
    background: #F4F4F4;
    padding: rem(160) 0 rem(198);
    &__inner {
      max-width: calc(100% - 32px);
      width: var(--G-max-pc-width);
      @include breakpoint(ready) {
        width: var(--G-max-tablet-width);
      }
      @include breakpoint(mobile) {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
