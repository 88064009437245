.event {
  @include flex(flex-start, center);
  @include size(100%, auto);
  background-image: url($G-path-content + 'event/event_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 45% !important;
  padding: rem(252) 0 rem(313);
  width: 100%;
  background-attachment: fixed;
  min-height: 100vh;
  @include breakpoint(mobile) {
    padding: 72px 0 54px;
  }

  &__inner {
    @include flex(flex-start, flex-start);
    max-width: rem(864);
    width: calc(100% - 32px);
    .event-head {
      @include flex(flex-start, flex-start, row);
      gap: 8px;
      position: relative;
      z-index: 1;

      @include breakpoint(minMobile) {
        width: 100%;
        display: grid;
        // grid-template-columns: repeat(2, 1fr);
        grid-template-columns: 1fr;
        // align-items: center;
        // gap: 16px;
      }
      button {
        background-color: var(--white);
        border-radius: 16px;
        padding: 12px 24px 10px;
        @include breakpoint(mobile) {
          padding: 12px 20px 10px;
          min-height: 58px;
        }
        @media only screen and (max-width: 400px) {
          padding: 12px 0 10px;
        }
        &.soli-hvh {
          max-width: 260px;
          @include breakpoint(mobile) {
            max-width: 100%;
          }
        }
        &.soli-mev {
          max-width: 260px;
          @include breakpoint(mobile) {
            max-width: 100%;
          }
        }
        &.ticket {
          max-width: 212px;
          @include breakpoint(mobile) {
            max-width: 100%;
          }
        }
        &.soli {
          max-width: 190px;
          @include breakpoint(mobile) {
            max-width: 100%;
            padding: 12px 26px 10px;
          }
          @media only screen and (max-width: 400px) {
            padding: 12px 0 10px;
          }
        }
        &.isActive {
          background-color: #000;
          p {
            color: #fff;
            font-weight: 400;
          }
        }
        p {
          @include string(20px, normal, normal, 500, center, #9C9C9C, 'option');
          @include breakpoint(mobile) {
            @include string(14px, normal, normal, 500, center, #9C9C9C, 'option');
          }
        }
      }
    }
    .content-rank {
      background-color: var(--white);
      border-radius: 16px;
      width: 100%;
      margin-top: rem(28);
      position: relative;
      @include breakpoint(mobile) {
        margin-top: 24px;
      }
      .content-image {
        @include same(100%);
        max-width: 340px;
        max-height: 277px;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-100%);
        object-fit: contain;
        @include breakpoint(mobile) {
          display: none;
        }
      }
      &__inner {
        padding: rem(68) rem(54) rem(55);
        @include breakpoint(mobile) {
          padding: 42px 22px 32px;
        }
        &--main-event {
          .title-inner {
            p {
              @include string(rem(40), normal, normal, 400, left, #000, 'option');
              margin-bottom: 8px;
              @include breakpoint(mobile) {
                @include string(20px, normal, normal, 400, left, #000, 'option');
              }
            }
            span {
              @include string(rem(20), rem(24), normal, 600, left, #000, 'normal');
              @include breakpoint(mobile) {
                @include string(12px, 16px, normal, 700, left, #000, 'normal');
              }
            }
          }
          .content-inner {
            @include flex(space-between, center, row);
            margin: rem(39) 0 rem(80);
            padding: 0 16px;
            @include breakpoint(mobile) {
              gap: 10px;
              margin: 16px 0 71px;
              padding: 0 8px;
            }
            li {
              @include flex;
              img {
                @include same(100%);
                object-fit: contain;
                &.mobile {
                  display: none;
                  @include breakpoint(minMobile) {
                    display: block;
                  }
                }
                @include breakpoint(minMobile) {
                  &.desktop {
                    display: none;
                  }
                }
              }
              .name-tag {
                @include flex;
                height: 28px;
                padding: 0 16px;
                background-color: #000;
                border-radius: 100px;
                margin-top: 9px;
                @include breakpoint(mobile) {
                  height: 16px;
                  padding: 0 8px;
                }
                p {
                  @include string(rem(20), rem(24), normal, 600, center, #fff, 'normal');
                  @include breakpoint(mobile) {
                    @include string(12px, 16px, normal, 500, center, #fff, 'normal');
                  }
                }
              }
            }
          }
        }
        &--title {
          @include flex(flex-start, flex-start);
          margin-bottom: rem(54);
          @include breakpoint(mobile) {
            margin-bottom: 32px;
          }
          p {
            @include string(rem(40), normal, normal, 400, left, #000, 'option');
            @include breakpoint(mobile) {
              @include string(20px, normal, normal, 400, left, #000, 'option');
            }
          }
          span {
            @include string(rem(20), rem(24), normal, 700, left, #000, 'normal');
            margin-top: rem(8);
            display: inline-block;
            @include breakpoint(mobile) {
              @include string(12px, 16px, normal, 700, left, #000, 'normal');
            }
          }
        }
        .table-inner {
          position: relative;
          width: 100%;
          margin-bottom: rem(16);
          border-bottom: 1px solid var(--black);
          colgroup {
            col {
              &.rank {
                width: 10%;
                @include breakpoint(mobile) {
                  width: 10%;
                }
              }
              &.nickname {
                width: 30%;
                @include breakpoint(mobile) {
                  width: 25%;
                }
              }
              &.fee {
                width: 30%;
                @include breakpoint(mobile) {
                  width: 32.5%;
                }
              }
              &.rewards {
                width: 30%;
                @include breakpoint(mobile) {
                  width: 32.5%;
                }
              }
            }
          }
          thead {
            tr {
              th {
                padding-bottom: rem(32);
                &:first-of-type {
                  p {
                    text-align: center;
                  }
                }
                &:not(:first-of-type, :nth-of-type(2)) {
                  p {
                    text-align: right;
                  }
                }
                &.fee {
                  padding-right: rem(30);
                  .m-break {
                    display: none;
                  }
                  @include breakpoint(mobile) {
                    padding-right: 0;
                    .m-break {
                      display: block;
                    }
                  }
                }
                p {
                  @include string(rem(20), rem(24), normal, 700, left, var(--black), 'normal');
                  span {
                    display: none;
                  }
                  @include breakpoint(minMobile) {
                    @include string(12px, 16px, normal, 700, left, var(--black), 'normal');
                  }
                  @include breakpoint(mobile) {
                    span {
                      display: block;
                    }
                  }
                }
              }
            }
            border-bottom: 1px solid var(--black);
          }
          tbody {
            tr {
              &:first-of-type {
                td {
                  padding: 16px 0 0;
                }
              }
              &:last-of-type {
                td {
                  padding: 0 0 16px;
                }
              }
              td {
                height: rem(98);
                vertical-align: middle;
                @include breakpoint(minMobile) {
                  height: 44px;
                }
                &:first-of-type {
                  p {
                    text-align: center;
                  }
                }
                &:not(:first-of-type, :nth-of-type(2)) {
                  p {
                    text-align: right;
                  }
                }
                &.fee {
                  padding-right: rem(30);
                  @include breakpoint(mobile) {
                    padding-right: 0;
                  }
                }
                p {
                  @include string(rem(20), rem(24), normal, 700, left, var(--black), 'normal');
                  word-break: break-all;
                  .m-break {
                    display: none;
                  }
                  @include breakpoint(minMobile) {
                    @include string(12px, 16px, normal, 700, left, var(--black), 'normal');
                  }
                  @include breakpoint(mobile) {
                    span {
                      display: none;
                    }
                    .m-break {
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
        .rank-body {
          @include flex;
          width: 100%;
          min-height: rem(781);
          @include breakpoint(mobile) {
            min-height: 607.44px;
          }
          @include breakpoint(minMobile) {
            min-height: 471px;
          }
          .progress-custom {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(50%, -50%);
          }
          p {
            @include string(rem(24), rem(24), normal, 700, left, var(--black), 'normal');
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .rank-foot {
          @include flex(space-between, center, row);
          @include breakpoint(mobile) {
            @include flex;
            .search-bar {
              width: 100%;
            }
            .pagenation-custom {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}



/* pagenation custom */
.MuiPagination-root.pagenation-custom {
  @include flex;
  .MuiPaginationItem-root {
    svg {
      color: var(--black);
    }
    &.Mui-disabled {
      opacity: 0;
    }
  }
  .MuiPagination-ul {
    li {
      .MuiPaginationItem-ellipsis {
        @include string(20px, 32px, normal, 500, center, #9C9C9C, 'normal');
        @include breakpoint(mobile) {
          @include string(18px, normal, normal, 500, center, #9C9C9C, 'normal');
          min-width: 26px;
          height: 26px;
        }
      }
      .MuiButtonBase-root {
        @include string(20px, 32px, normal, 500, center, #9C9C9C, 'normal');
        @include breakpoint(mobile) {
          @include string(18px, normal, normal, 500, center, #9C9C9C, 'normal');
          min-width: 26px;
          height: 26px;
          margin: 0;
        }
        &:hover {
          background-color: transparent;
        }
        &.Mui-selected {
          font-weight: 700;
          color: var(--black);
          background-color: transparent;
        }
      }
    }
  }
}