@import "./variables.scss";

@mixin noBtnMotion {
  &:before {
    display: none;
  }
  .v-ripple__container {
    display: none !important;
  }
}

@mixin transitionSet {
  transform: perspective(500px) rotate(0.001deg);
}

@mixin breakpoint($size) {
  @if $size == minMobile {
    @media only screen and (max-width:570px) {
      @content;
    }
  }
  @else if $size == mobile {
    @media only screen and (max-width: 768px) {
      @content;
    }
  }
  @else if $size == noneTuchDevice {
    @media only screen and (min-width:960px) {
      @content;
    }
  }
  @else if $size == tablet {
    @media only screen and (max-width: 1280px) {
      @content;
    }
  }
  @else if $size == ready {
    @media only screen and (max-width: 1700px) {
      @content;
    }
  }
}

@mixin setIcon($width, $height, $file, $color) {
  @include size($width, $height);
  mask-image: url($G-path-icon + '#{$file}.svg');
  -webkit-mask-image: url($G-path-icon + '#{$file}.svg');
  background-color: $color;
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-repeat: no-repeat;
}

@mixin disableDrag() {
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none
}

@mixin string(
  $size,
  $height,
  $spacing,
  $weight: normal,
  $align: left,
  $color: var(--text-primary),
  $font
) {

  @if $font == option {
    font-family: 'Black Han Sans';
    font-size: $size;
    line-height: $height;
    letter-spacing: $spacing;
    font-weight: $weight;
    text-align: $align;
    color: $color;
  }
  @else if $font == normal {
    font-family: 'Pretendard';
    font-size: $size;
    line-height: $height;
    letter-spacing: $spacing;
    font-weight: $weight;
    text-align: $align;
    color: $color;
  }
}

@mixin size($width, $height: auto) {
  width: $width;
  height: $height;
  @content;
}

@mixin same($size) {
  width: $size;
  height: $size;
  @content;
}

@mixin flex($content: center, $align: center, $col: column) {
  display: flex;
  justify-content: $content;
  align-items: $align;
  flex-direction: $col;
}

@mixin updateHeader {
  @include flex(flex-start, flex-start, row);
  gap: 5.5px;
  &::after {
    @include same(6px);
    content: '';
    display: block;
    background-color: #ff5c72;
    border-radius: 50%;
  }
}

@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin iconSet {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

@mixin scrollCustom {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #fff;
  }
}

@mixin scrollCustomSmall($color: #fff) {
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: $color;
  }
}
