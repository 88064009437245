footer {
  @include flex;
  width: 100%;
  background-color: #000;
  .footer-inner {
    @include flex(space-between, center, row);
    max-width: calc(100% - 32px);
    width: var(--G-max-pc-width);
    padding: rem(56) 0;
    @include breakpoint(ready) {
      width: var(--G-max-tablet-width);
    }
    @include breakpoint(tablet) {
      @include flex(flex-start, flex-start, column-reverse);
      padding: 28px 0;
    }
    .footer-desc {
      .m-break {
        display: none;
        @include breakpoint(mobile) {
          display: block;
        }
      }
      p {
        @include string(rem(16), rem(24), normal, 500, left, var(--text), 'normal');
        @include breakpoint(mobile) {
          @include string(14px, 20px, normal, 500, left, var(--text), 'normal');
        }
      }
    }
    .footer-logo {
      @include size(rem(147), rem(31));
      @include breakpoint(mobile) {
        @include size(113px, 23px);
        margin-bottom: 16px;
      }
    }
  }
}