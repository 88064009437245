.phone-swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  .phone {
    z-index: 1;
  }
  .phone-swiper-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    .phones {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10;
    }
    .slider-component {
      width: 100%;
      height: 100%;
      &.swiper {
        width: calc(100% - 4px);
        height: 100%;
        border-top-right-radius: 60px;
        border-top-left-radius: 60px;
        @include breakpoint(mobile) {
          border-top-right-radius: 25px;
          border-top-left-radius: 25px;
        }
        .swiper-wrapper {
          .swiper-slide {
            &:first-of-type {
              // background-color: red;
            }
            &:last-of-type {
              // background-color: blue;
            }
          }
        }
      }
    }
  }
}

.set-swiper-wrapper {
  position: relative;
  &:not(:last-of-type) {
    margin-bottom: rem(120);
    @include breakpoint(mobile) {
      margin-bottom: 40px;
    }
  }
  .btn-next, .btn-prev {
    @include breakpoint(tablet) {
      display: none;
    }
    &.swiper-button-disabled {
      // opacity: 0.5;
      cursor: default;
      transition: none;
      pointer-events: none;
    }
    @include flex;
    @include same(rem(40));
    &::before {
      @include same(rem(40));
      content: '';
      display: block;
    }
    &.swiper-button-disabled {
      &::before {
        filter: invert(0.05);
        opacity: 0.7;
      }
      filter: invert(0.05);
      opacity: 0.7;
    }
  }
  .btn-next {
    position: absolute;
    top: calc(50% + 14px);
    right: -24px;
    transform: translate(100%, -50%);
    &::before {
      background: url($G-path-icon + 'right_btn.png') $G-img-contain;
    }
  }
  .btn-prev {
    position: absolute;
    top: calc(50% + 14px);
    left: -24px;
    transform: translate(-100%, -50%);
    &::before {
      background: url($G-path-icon + 'left_btn.png') $G-img-contain;
    }
  }
}
.slider-component {
  &.swiper {
    display: flex;
    flex-direction: column-reverse;
    @include breakpoint(tablet) {
      align-items: center;
    }
  }
  &__header {
    @include flex(space-between, center, row);
    margin-bottom: 16px;
    @include breakpoint(mobile) {
      margin-bottom: 12px;
    }
    .slider-title {
      @include flex(flex-start, center, row);
      gap: 12px;
      @include breakpoint(tablet) {
        @include flex;
      }
      .title-info {
        p {
          @include string(rem(20), rem(28), normal, 600, left, var(--black), 'option');
          @include breakpoint(tablet) {
            @include string(16px, 20px, normal, 500, center, var(--black), 'option');
          }
        }
      }
    }
  }
  .swiper-wrapper {
    .swiper-slide {
      // &.swiper-slide-active {
      //   margin-left: 16px;
      //   &:last-of-type {
      //     margin-left: 0;
      //     margin-right: 16px;
      //   }
      // }
      &.card-item {
        a {
          border-radius: var(--box-radius);
          overflow: hidden;
          display: flex;
          width: inherit;
          height: 100%;
          aspect-ratio: 16 / 9;
          .hover-wrapper {
            display: none;
          }
          img {
            width: inherit;
          }
          @include breakpoint(mobile) { // TODO: 임시 이미지 height
            .tempImage-wrapper {
              min-height: 188px;
            }
          }
        }
      }
    }
  }
}

// 임시 이미지 처리
.tempImage-wrapper {
  @include flex;
  width: 100%;
  min-height: 150px;
  background-color: #9B9B9B;
}