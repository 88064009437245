* {
  font-family: 'Pretendard';
  -webkit-tap-highlight-color: transparent;

  img {
    -webkit-user-drag: none;
  }
}

:root {
  font-size: 18px;
  @include breakpoint(tablet) {
    font-size: 16px;
  }
  @include breakpoint(mobile) {
    font-size: 14px;
  }

  --G-max-pc-width: 1304px;
  --G-max-tablet-width: 1128px;
  --G-gnbside-width: 13.77rem;
  --G-header-hegiht: 3.61rem;
  --G-header-hegiht-m: 56px;

  --box-radius: 8px;
  --white: #ffffff;
  --black: #2D2F32;
  --text: #9C9C9C;
  --yellow: #FFD600;
}

body {
  font-size: calc(50 / 16) * 1px;
}

html,
body,
#root {
  background-color: var(--white);
  color: var(--black);
  height: 100%;
}

html {
  overflow-x: hidden;
  font-size: 1rem;
}

span,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
button {
  transform: skew(-0.1deg);
}
// textarea,
// input,
// select

a,
textarea,
input,
select,
button {
  font-size: 18px;
  @include breakpoint(tablet) {
    font-size: 16px;
  }
  @include breakpoint(mobile) {
    font-size: 14px;
  }
}

textarea,
input,
select {
  outline: none;
  border: none;
  background: none;
}

button {
  outline: none;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
}

body {
  line-height: 1;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  color: var(--black) !important;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
select {
  vertical-align: middle;
}

ol,
ul {
  list-style: none;
}
